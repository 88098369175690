<app-nav></app-nav>
<section>
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-10">
                <h2 [ngClass]="{'bigger-t title-line-height mt-5 ms-5': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'turquesa':!global.color_filter,
          'black': global.color_filter}">Políticas de privacidad</h2>
        <p
          [ngClass]="{'mb-3 p-5': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          ASEMBIS, a través de su Sitio Web, podrá solicitar información personal (en los casos que se requiera, se le
          hará saber). Por lo tanto, la utilización de nuestro Sitio Web supone que usted reconoce haber leído y
          aceptado nuestra Política de Privacidad y Seguridad que aquí se presenta, así como nuestros Términos y
          Condiciones.<br><br>
          Política de Privacidad y Seguridad<br><br> Recolección y uso de la información<br><br>

          La política de Privacidad y Seguridad de ASEMBIS aplica a la recopilación y uso de la información que usted
          nos suministre a través de nuestro Sitio Web, dicha información será utilizada únicamente por ASEMBIS en el
          cumplimiento de sus fines.<br><br>

          ASEMBIS utiliza sistemas automatizados para la detección y prevención de ataques informáticos, minimizando la
          posibilidad de sufrir daños o alteraciones en la información disponible en el Sitio Web. Los mismos nos
          permiten generar reportes y bitácoras de los accesos indebidos desde y hacia nuestro sitio.<br><br>

          La información se utilizará con el propósito para la que fue solicitada. ASEMBIS respeta su derecho a la
          privacidad, y no proveerá a terceras personas la información personal de sus usuarios sin su consentimiento, a
          no ser que sea requerido por las leyes vigentes.<br><br>

          <strong>Cookies</strong><br>
          Utilizamos tecnología de rastreo con “cookies”. El Usuario puede utilizar la configuración de su navegador
          para deshabilitar el uso de “cookies”. Si son deshabilitadas podrá seguir navegando en nuestro Sitio Web, pero
          con algunas restricciones.<br><br>

          <strong>Seguridad</strong><br>
          La transferencia de datos personales y la integridad del Sitio Web la protegemos utilizando tecnología de
          codificación SSL (Secure Sockets Layer) de 128 bits como canal seguro en las transacciones que usted realiza.<br><br>

          Nuestra autoridad certificadora es Let's Encrypt y el dominio para el cual se emitió este certificado es
          www.asembis.org<br><br>

          El dueño de los certificados es ASEMBIS con domicilio en, detrás de la Iglesia Católica de Purral, San José,
          Guadalupe, 10801. El período de validez es del July 22, 2024 al October 20, 2024, con renovaciones automaticas
          cada 3 meses.<br><br>

          Dado que ningún sistema puede garantizar seguridad completa, se trata de mantener la información que el
          Usuario suministra o accede lo más segura posible, incluyendo su seguridad física en la ubicación del servidor
          donde la información está almacenada.<br><br>

          <strong>Actualización de datos</strong><br>


          Periódicamente realizamos revisión y actualización de los datos que usted nos proporciona a través del Sitio
          Web que puedan ser modificados.<br><br>

          <strong>Actualización de políticas</strong><br>


          La política de privacidad y seguridad de ASEMBIS se revisa con una frecuencia mensual. Podremos modificarla
          periódicamente, en dicho caso comunicaremos la política modificada en nuestro Sitio Web.<br><br>

          <strong>Contáctenos</strong> <br><br>


          ASEMBIS está comprometida en proteger los datos que usted nos proporcione. Si tiene alguna pregunta,
          observación o inquietud sobre nuestra Política de Privacidad y Seguridad o nuestros Términos y Condiciones,
          póngase en contacto con nosotros enviándonos un mensaje de correo electrónico a
          <strong>contacto&#64;asembis.org</strong></p>
            </div>
        </div>
    </div>
</section>
<app-foot></app-foot>