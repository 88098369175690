import { Component } from '@angular/core';
import {GLOBAL} from "../../services/GLOBAL";

@Component({
  selector: 'app-politicas',
  templateUrl: './politicas.component.html',
  styleUrls: ['./politicas.component.css']
})
export class PoliticasComponent {
  public global = GLOBAL;
}
